import React, {useEffect, useState} from 'react'
import WidgetsPage from '../../app/modules/widgets/WidgetsPage'
import {ToastContainer, toast} from 'react-toastify'
import axios from 'axios'
import {Loading} from '../Loading'
import {KTSVG, useDebounce} from '../../_metronic/helpers'
import {showToast, useCallApi} from '../../app/modules/utils'
import SearchComponent from '../SearchComponent'
import { debounce } from '@mui/material'
import { UsersListFilter } from '../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import { Languages } from '../../_metronic/partials/layout/header-menus/Languages'
import { DropDownLanguage } from '../componentsProduit/DropDownLanguage'
import ModalAddSlide from './ModalAddSlide'
import { ItemSlider } from './ItemSlider'

type Props = {
  className: string
}
const ListSlider: React.FC<Props> = ({className}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  const [sliders, setSliders] = useState([])
  const [file, setFile] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const callApi = useCallApi()

  const getsliders = async () => {
    const {data} = await callApi({
      route: 'api/sliders',
      method: 'GET',
    })
    setSliders(data)
    setLoading(false)
  }
//   const getCategoryByName= async ()=>{
//     setLoading(true)
//     const {data}= await callApi({
//         route: 'api/all?categorie='+debouncedSearchTerm,
//         method: "GET",
//       });
//       setSliders(data)
//       setLoading(false)
// }
const deleteSliders = async (id: number) => {
  if (window.confirm('Voulez-vous vraiment supprimer ce slide !')) {

    const filteredsliders = sliders.filter((categorie: any) => categorie.id !== id)
    setSliders(filteredsliders)
     toast.success("Slider supprimé")
     const {data} = await callApi({
      route: 'api/sliders/' + id,
      method: 'DELETE',
    })
   
  }
}
  useEffect(() => {
    getsliders()
  }, [])

//   useEffect(() => {
//     getCategoryByName()
//   }, [debouncedSearchTerm])
 


  return (
    <div>
      <ToastContainer />
      <div className={`card mb-5 ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
            {/* <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
            <SearchInput searching={searching} placeholder="Catégoeie"/> 
            </span>
          </h3>*/}
           <div className='card-title mb-3 '>
          {/* <SearchComponent action="update" placeholder={"Recherche Slider"} searchQuery={[searchTerm,setSearchTerm]} /> */}
           </div>     
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              type='button'
              className='btn btn-sm btn-primary '
              data-bs-toggle='modal'
              data-bs-target='#modaladdcategorie'
            >
              <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
              Nouveau slide
            </button>
             <ModalAddSlide getSlider={getsliders}/>
          </div>
          
        </div>
        </div>
        <div className='row g-3 g-xl-3'>
        {loading ? (
          <Loading />
        ) : (
        
                  sliders.map((slider, index) => {
                    return (
                      <div className='col-xl-4'>
                        <ItemSlider getsliders={getsliders} categorie={slider} deleteSlider={deleteSliders}/></div>
                      // <ItemCategorie
                      //   deleteSliders={deleteSliders}
                      //   key={index}
                      //   data={categorie}
                      //   className=''
                      // />
                    )
                  })
              
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
     
    </div>
    </div>
  )
}

export default ListSlider
