import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'
import { DropDownProduct } from './DropDownProduct'



type Props = {
  className: string,
  product:any,
  deleteProduct:(slug :string)=>void

}

export const ItemProduct: React.FC<Props> = ({className,product,deleteProduct}) => {

  
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-55px me-5'>
              <img src={"https://api.nanogiga.fcpo.agency/"+product.imageurl} alt={product.slug} />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {product.titre}
              </a>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          {/* begin::Menu */}
          <div className='my-0'>
            
            <DropDownProduct deleteProduct={deleteProduct} slug={product.slug}  id={product.id}/>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-7'>
          {/* begin::Text */}
          <div className='text-gray-800 mb-5'style={{height:"60px"}}>
           {
             (product.description).split(" ")
             .slice(0, 35)
             .join(" ")
            }...
           
          </div>
          {/* end::Text */}

          {/* begin::Toolbar */}
          <div className='d-flex align-items-center mb-5 '>
            <a
              href='#'
              className='btn btn-sm btn-light-secondary btn-color-muted btn-active-light-success px-4 py-2 me-1 '
            >
              
               {product.categorie.parent.categorie} 
            </a>
            &rarr;  &nbsp;
                   <a
              href='#'
              className='btn btn-sm btn-light-danger btn-color-muted btn-active-light-success px-4 py-2 ml-1'
            >
              
               {product.categorie.categorie}
            </a>
          </div>
         
          {/* end::Toolbar */}
        </div>
        {/* end::Post */}

        {/* begin::Separator */}
        <div className='separator mb-4'></div>
        {/* end::Separator */}

        {/* begin::Reply input */}
        {/* <form className='position-relative mb-6'>
          <textarea
            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
            rows={1}
            placeholder='Reply..'
          ></textarea>

          <div className='position-absolute top-0 end-0 me-n5'>
            <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
              <KTSVG
                path='/media/icons/duotune/communication/com008.svg'
                className='svg-icon-3 mb-3'
              />
            </span>

            <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
              <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-2 mb-3' />
            </span>
          </div>
        </form> */}
        {/* edit::Reply input */}
      </div>
      {/* end::Body */}
    </div>
  )
}
