/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'
import { DropDownPartner } from './DropDownPartner'
import ModalPartenaireUpdate from './ModalPartnerUpdate'


type Props = {
  className: string
  title: string
  description: string
  avatar: string
  deletePartenaire:(slug:number)=>void
  id:number
  link:string
}

const ItemPartenaire: React.FC<Props> = ({link,className, title, description, avatar,deletePartenaire,id}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bold text-dark'>Tasks Overview</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Pending 10 tasks</span> */}
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold"
            role="button" id="dropdownMenuLnk"
            data-bs-toggle="dropdown"
             aria-expanded="false"

          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <DropDownPartner  deletePartenaire={deletePartenaire} id={id}/>
          <ModalPartenaireUpdate link={link}title={title} avatar={avatar} id={id}/>
          {/* end::Menu */}
        </div>
      </div>
      <div className='card-body  align-items-center pt-3 pb-0'>
        

        <img src={"https://api.nanogiga.fcpo.agency/"+avatar} alt='' className='align-self-end h-100px' />
        <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
          <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
            {title}
          </a>
           
          <span
            className='fw-semibold text-muted fs-5'
            dangerouslySetInnerHTML={{__html: description}}
          ></span>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ItemPartenaire}
