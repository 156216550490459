import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { AddProduct } from '../pages/managementProducts/AddProduct'
import AddPartner from '../pages/managementPartners/ListePartenaitres'
import ListProduct from '../pages/managementProducts/ListProduct'
import ListeCategories from '../pages/managementCategories/ListeCategories'
import ListePartenaitres from '../pages/managementPartners/ListePartenaitres'
import { UpdateProduct } from '../pages/managementProducts/UpdateProduct'
import { AddSubProduct } from '../pages/managementProducts/AddSubProduct'
import { AddLangProduct } from '../pages/managementProducts/AddLangProduct'
import {  PageListPosts } from '../pages/managementPosts/ListPosts'
import ListEstimates from '../pages/estimates/ListEstimates'
import ListSlider from '../../components/componentSlider/ListSlider'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
      
        <Route
          path='products/add'
          element={
            <AddProduct/>
          }
        />

         <Route
          path='products/all'
          element={
            <ListProduct/>
          }
        />
           <Route
          path='/slider/all'
          element={
            <ListSlider className=''/>
          }
        />
        <Route
          path='/pages/managementProducts/update-product/:slug'
          element={
            <UpdateProduct/>
          }
        />
         <Route
          path='/pages/managementProducts/add-sub-product/:slug'
          element={
            <AddSubProduct/>
          }
        />
          <Route
          path='/pages/managementProducts/add-lang-product/:slug'
          element={
            <AddLangProduct/>
          }
        />
         <Route
          path='categories/all'
          element={
            <ListeCategories/>
          }
        />
        <Route
          path='/partners/all'
          element={
            <ListePartenaitres/>
          }
        />
       <Route
          path='/posts/all'
          element={
            <PageListPosts/>
          }
        />
        <Route
          path='/estimates/all'
          element={
            <ListEstimates/>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
