import React, { useEffect, useState } from 'react';
import { useCallApi } from '../app/modules/utils';
import { log } from 'console';

type Props = {
  subcategorieId: any;
  categorieId: any;
  setSelectSubCategorie: any;
  action: string;
};

export const DropDownCategorie: React.FC<Props> = ({
  subcategorieId,
  categorieId,
  setSelectSubCategorie,
  action,
}) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [idCategorie, setIdCategorie] = useState<number | undefined>();

  const callApi = useCallApi();

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await callApi({
        route: 'api/categories',
        method: 'GET',
      });
      setCategories(data);
    };

    getCategories();
  }, [subcategorieId, categorieId]);

  useEffect(() => {
    const getSubCategories = async () => {
      let parentId;
      if (action === 'update') {
        parentId = idCategorie ?? categorieId.id;
      } else {
        parentId = idCategorie;
      }

      if (parentId) {
        const { data } = await callApi({
          route: `api/all?parent=${parentId}`,
          method: 'GET',
        });
        setSubCategories(data);
      }
    };

    getSubCategories();
  }, [idCategorie, action, categorieId]);

  const handleCategoryChange = async (event: any) => {
    setIdCategorie(event.target.value);
  };

  return (
    <>
      <div className="col">
        <select
          name="categorie"
          id="markselect"
          className="form-select"
          onChange={handleCategoryChange}
        >
          {action === 'add' && <option>Parent</option>}
          {categories.map((categorie: any, index) => (
            <option
              key={index}
              value={categorie.id}
              selected={categorie.id === categorieId.id}
            >
              {categorie.categorie}
            </option>
          ))}
        </select>
      </div>
      <div className="col">
        <select
          name="categorie"
          id="markselect"
          className="form-select"
          onChange={(e) => setSelectSubCategorie(e.target.value)}
        >
          {action === 'add' && <option>Categorie</option>}
          {subCategories.map((sub: any, index) => (
            <option
              key={index}
              value={sub.id}
              selected={sub.id === subcategorieId.id}
            >
              {sub.categorie}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
