import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import './css/mystyle.css'
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  createFormData, encodeHtmlTags, useCallApi } from '../../app/modules/utils';
import { Languages } from '../../_metronic/partials/layout/header-menus/Languages';
import { DropDownLanguage } from './DropDownLanguage';
import { useParams } from 'react-router-dom';
import { Loading } from '../Loading';
import EditorQuell from './EditorQuell';


interface EditorContent {
  application: any
  caracteristique: any
  specification: any
  produits:any
}

export const FormAddLangProduct = () => {
  const [lang, setLang] = useState({
    name:"",
    lang:"",
    img:""
})

  const callApi = useCallApi();
  const [image, setImage] = useState<File | any>(null)
  const [pdf, setPdf] = useState<File | any>(null)
  const [loading, setLoading] = useState(false)
  const {slug}=useParams()
  const [editorContent, setEditorContent] = useState<any>({
    application: "",
    caracteristique: "",
    specification: "",
    produits:""
  })
  const initialValues :any= {
   
    titre: "",
    description:"",
    avantages:""

  
  }

  const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
    setEditorContent({
      ...editorContent,
      [editorKey]: newEditorState,
    })
  }


  
  const handleSubmit = async(values:any) => {
    
    try {
      setLoading(true)
      const dataTosend = {
        produit:slug,
        pdf: pdf,
        titre: values.titre,
        description: values.description,
        lang:lang.lang,
        avantages: values.avantages,
        applications: encodeHtmlTags(editorContent.application) ,
        caracteristiques: encodeHtmlTags(editorContent.caracteristique),
        specifications: encodeHtmlTags(editorContent.specification),
        produits:encodeHtmlTags(editorContent.produits)
       };
      
      const formData = createFormData(dataTosend);  
      const {status}  = await callApi({
      route: `api/translate_produits`,
      method: "POST",
      body:formData
    }) 
     setLoading(false)
     toast.success("ajouté aves succès")
    } catch (error:any) {
      setLoading(false)
      toast.success(error?error.response.data.detail:"une erreur s'est produite!")
    }
   


  
  

}

  return (
    <>
      <ToastContainer />
     

      <Formik initialValues={initialValues} onSubmit={(values,{resetForm})=>{
        resetForm({values:""})
        handleSubmit(values)
      } 
      }>
        {() => (
          <>
         
            <Form className='mx-5'>
            <div className='form-group '>
            <DropDownLanguage addlang={true} selectedLang={[lang,setLang]}/>
            </div>
              <div className='form-group mb-5  '>

                <label className='mb-1  fw-bold fs-6' htmlFor='titre'>
                  Titre :
                </label>
                <Field
                  type='text'
                  className='form-control'
                  name='titre'
                  id='titre'
                  placeholder='Titre'
                  
                />
              </div>
              <div className='mb-3'>
                <label
                  htmlFor='exampleFormControlTextarea1'
                  className='form-label  fw-bold fs-6'
                >
                  Description :
                </label>
                <Field
                  as='textarea'
                  className='form-control'
                  name='description'
                  id='exampleFormControlTextarea1'
                  rows={4}
                  placeholder='Description'
                  
                />
              </div>
              <div className='form-group mb-5  '>
                <label className='mb-1  fw-bold fs-6' htmlFor='titre'>
                  Avantages :
                </label>
                <Field
                  as='textarea'
                  rows={4}
                  className='form-control'
                  name='avantages'
                  id='avantage'
                  placeholder='Avantages'
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Specifications :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"specification"}/>
              </div>

              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Caracteristiques :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"caracteristique"}  />

              </div>

              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  PDF :
                </label>
                <br></br>
                <div>
                  {' '}
                  <Button variant='contained' component='label'>
                    Votre fichier PDF
                    <input
                      hidden
                      accept='application/pdf'
                      type='file'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files && e.target.files[0]) {
                          console.log(e.target.files[0].name)

                          setPdf(e.target.files[0])
                        }
                      }}
                    />
                  </Button>
                  {pdf && pdf.name}
                </div>
              </div>

              <div className='mb-3 ' style={{}}>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Applications :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"application"}/>

              </div>
              <div className='mb-3 ' style={{}}>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Liste des Produits :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"produits"}/>

              </div>

              <div className='d-flex justify-content-end '>
                {
                  loading?  <button type='button' className='btn btn-primary text-end'>
                   <Loading/>
                </button>:  <button type='submit' className='btn btn-primary text-end'>
                  Ajouter
                </button>
                }
              
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

