import React, { useEffect, useState } from 'react'
import { FeedsWidget2, FeedsWidget3, FeedsWidget4 } from '../../_metronic/partials/widgets'
import { useCallApi } from '../../app/modules/utils'
import { Loading } from '../Loading'
import { ItemProduct } from './ItemProduct'
import SearchComponent from '../SearchComponent'
import { useDebounce } from '../../_metronic/helpers'
import { toast } from 'react-toastify'

export const ListProducts = () => {
  const [searchTerm, setSearchTerm] = useState<string>('')
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    // Effect for API call
  console.log(debouncedSearchTerm);
  
    const callApi = useCallApi();
    const [products, setProducts] = useState<any>([])
    const [loading, setLoading] = useState(true)

    const getProducts= async ()=>{
        const {data}= await callApi({
            route: `api/produits.json`,
            method: "GET",
          });
          setProducts(data)
          setLoading(false)
    }
    const getProductByName= async ()=>{
      setLoading(true)
      const {data}= await callApi({
          route: 'api/produits.json?titre='+debouncedSearchTerm,
          method: "GET",
        });
        setProducts(data)
        setLoading(false)
  }

    const deleteProduct= async(slug:string) =>{
      try {
        if (window.confirm("voulez-vous vraimenet supprimer ce produit !")) {
          const filteredproducts = products.filter((product:any )=> product.slug !== slug);
          setProducts(filteredproducts);
          const {data}= await callApi({
            route: `api/produits/${slug}`,
            method: "DELETE",
          });
          setProducts(data)
          setLoading(false)
          toast.success(" produit supprimé")
        } 
      } catch (error) {
        setLoading(false)
        toast.error("une erreur s'est produite !")
      }
      
    }

    useEffect(() => {
      
       getProducts()
        
    
    }, [])
    useEffect(
      () => {
        if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
             getProductByName()
        }
      },
      [debouncedSearchTerm] // Only call effect if debounced search term changes
      // More details about useDebounce: https://usehooks.com/useDebounce/
    )
  return (
    <> 
       <div className={`card  mb-5 `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
       {/*  <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <SearchInput searching={searching} placeholder="Catégoeie"/>
          </span>
        </h3> */}
      <SearchComponent action='add' placeholder='Recherche S-sous catégorie' searchQuery={[searchTerm,setSearchTerm]} />

       
      </div>
    </div>
    <div className='row'>
        {

          loading ?<Loading/>:
           products.map((product:any,index:number)=>{
            return  <div className="col-sm-6 mb-3"><ItemProduct deleteProduct={deleteProduct} product={product} className=''key={index}/></div>

           })
         
        }
     

    </div>
    </>
   
  )
}
