import axios from 'axios'
import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {createFormData, encodeHtmlTags, useCallApi} from '../../app/modules/utils'
import {Loading} from '../Loading'
import EditorQuell from '../componentsProduit/EditorQuell'


const ModalAddCategory = () => {
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>(null)
  const [file, setFile] = useState<string>('')
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
 
  const initialValues = {
    parent: 8,
    description: '',
    categorie: '',
  }
  const getCategories = async () => {
    const {data} = await callApi({
      route: 'api/all',
      method: 'GET',
    })
    setCategories(data)
  }
  const handelSumit = async (values: any) => {
    setLoading(true)
    try {
      const dataTosend = {
        image: image,
        categorie: values.categorie,
        parent: values.parent,
        description: encodeHtmlTags(description),
      }
   
     
      const formData = createFormData(dataTosend)
      const data = await callApi({
        route: `api/categories`,
        method: 'POST',
        body: formData,
      })
      toast.success(' categorie ajoutée')
      setLoading(false)

    } catch (error: any) {
      setLoading(false)
      toast.error('Error Adding : ' + error?.response.data.detail)
    }
  }
  useEffect(() => {
    getCategories()
  }, [])


  
  const handleEditorStateChange = (val:any) => {
   
    // setEditorContent();
    setDescription(val)

   
  };

  return (
    <>
      <ToastContainer />
      <div
        className='modal fade'
        id='modaladdcategorie'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, {resetForm}) => {
              handelSumit(values)
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='exampleModalLongTitle'>
                    Ajouter categorie
                  </h5>
                  <button
                    type='button'
                    className=' btn close'
                    data-bs-dismiss='modal'
                    aria-bs-label='Close'
                  >
                    <span aria-bs-hidden='true'>&times;</span>
                  </button>
                </div>
                <div
                  className='modal-body'
                  style={{height: ' 307px', overflow: 'hidden', overflowY: 'scroll'}}
                >
                  <form>
                    <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                      Photo :
                    </label>
                    <div className='form-group mb-5  '>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                      >
                        <div
                          id='image'
                          className='image-input-wrapper w-125px h-125px'
                          style={{backgroundImage: file == '' ? "url('')" : 'url(' + file + ')'}}
                        ></div>
                        <label
                          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          data-bs-dismiss='click'
                          title='Change avatar'
                        >
                          <i className='bi bi-pencil-fill fs-7'></i>

                          <input
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              // const reader = new FileReader();
                              // reader.onload=function(e){
                              //   setUrl(e.target?.result)
                              // }
                              // reader.readAsDataURL(e.target.files[0])
                              if (e.target.files && e.target.files[0]) {
                                setFile(URL.createObjectURL(e.target.files[0]))
                                setImage(e.target.files[0])
                              }
                            }}
                            type='file'
                            name='image'
                            accept='.png, .jpg, .jpeg'
                          />
                          <input type='hidden' name='avatar_remove' />
                        </label>

                        <span
                          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='cancel'
                          data-bs-toggle='tooltip'
                          data-bs-dismiss='click'
                          title='Cancel avatar'
                        >
                          <i className='bi bi-x fs-2'></i>
                        </span>
                      </div>
                    </div>
                    <div className='form-group mb-5'>
                      <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                        Categorie :
                      </label>
                   

                      <Field
                        type='text'
                        className='form-control'
                        id='exampleInputEmail1'
                        name='categorie'
                        aria-describedby='text'
                        placeholder='categorie'
                        required
                      />
                    </div>
                    <div className='form-group mb-5'>
                      <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                        Parent :
                      </label>

                      <Field as='select' name='parent' id='markselect' class='form-select'>
                        {categories.map((categorie: any, index) => {
                          return (
                            <option key={index} value={categorie.id}>
                              {categorie.categorie}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                    <div className='form-group mb-5'>
                      <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                        Description :
                      </label>
                      <EditorQuell value={{  handleEditorStateChange}} title={"description"}/>

                    </div>
                  </form>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                    Annuler
                  </button>
                  {loading ? (
                    <button className='btn btn-primary'>
                      <Loading />{' '}
                    </button>
                  ) : (
                    <button type='submit' className='btn btn-primary'>
                      Ajouter{' '}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ModalAddCategory
