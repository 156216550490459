import React from 'react'
import { Header } from '../../../_metronic/layout/components/header/Header'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { TablesWidget10, TablesWidget11, TablesWidget12, TablesWidget13 } from '../../../_metronic/partials/widgets';
import { EstimatesList } from '../../../components/componentsEstimates/EstimatesList';

const ListEstimates = () => {
    const usersBreadcrumbs: Array<PageLink> = [
        {
          title: "Gestion Devis",
          path: "#",
          isSeparator: false,
          isActive: false,
        },
      ];
      return (
        <>
        <Header />
          <PageTitle breadcrumbs={usersBreadcrumbs}>liste Devis </PageTitle>
          <EstimatesList className=''/>
          



        </>
      )
}

export default ListEstimates