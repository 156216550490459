import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {createFormData, decodeHtmlTags, encodeHtmlTags, useCallApi} from '../../app/modules/utils'
import {log} from 'console'
import {toast} from 'react-toastify'
import {Loading} from '../Loading'
import {DropDownLanguage} from '../componentsProduit/DropDownLanguage'
import {ContentState, EditorState, convertToRaw} from 'draft-js'
import EditorQuell from '../componentsProduit/EditorQuell'

type Props = {
  categorie: any
  getCategories:()=>void
}
interface EditorContent {
 
  description: any
}
export const ModalUpdateCategory: React.FC<Props> = ({categorie,getCategories}) => {
  const callApi = useCallApi()
  const [file, setFile] = useState<File | any>(null)
  const [categories, setCategories] = useState([])
  const [idCategorie, setIdCategorie] = useState<number | undefined>()
  const [lang, setLang] = useState({
    name: '',
    lang: 'en',
    img: '',
  })
  const [editorContent, setEditorContent] = useState<any>({
   
    description:""
  })
  const [category, setCategory] = useState(categorie.categorie)
  const [description, setDescription] = useState<string>(categorie.description)
  const [translate, setTranslates] = useState<any>()

  const [slug, setSlug] = useState(categorie.slug)

  const [loading, setLoading] = useState(false)

  const updateCategory = async () => {
    const dataTosend = {
      id: categorie.id,
      parent: idCategorie,
      image: file,
      categorie: category ,
      description: encodeHtmlTags(editorContent.description),
    }

    const formData = createFormData(dataTosend)
     setLoading(true)
   
    try {
      if (lang.lang=='en') {
        console.log(dataTosend);
              const {data, status} = await callApi({
            route: `api/categoriesupdate/` + categorie.id,
            method: 'POST',
            body: formData,
          })
        } else {
          const dataTosend = {
            categorietranslate: "api/categories/"+categorie.slug,
            lang: lang.lang,
            categorie: category,
            description: encodeHtmlTags(description),
          }
        
        
          const {data, status} = await callApi({
            route: `api/translate_categories/` + translate.id,
            method: 'PUT',
            body: dataTosend,
          })
         
        }
        setLoading(false)
        toast.success("modifiée")
        getCategories()
    } catch (error:any) {
      setLoading(false)
      toast.error("Error updating : "+error?.response.data.detail)
    }
 

   
  }
  const handleCategoryChange = async (event: any) => {
    setIdCategorie(event.target.value)
  }
  const getCategoriess = async () => {
    const {data} = await callApi({
      route: 'api/all',
      method: 'GET',
    })
    setCategories(data)
  }
  useEffect(() => {
    getCategoriess()
    console.log(description);
    
  }, [])

  useEffect(() => {
    setTranslates(categorie.translates.find((translate: any) => translate.lang === lang.lang))
  }, [lang])

  useEffect(() => {
    
    const translate: any = categorie.translates.find((translate: any) => translate.lang === lang.lang)
    setEditorContent({
     
      description: decodeHtmlTags(translate?.description || description)


     })
    
  }, [categorie, lang])


  const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
    setEditorContent({
      ...editorContent,
      [editorKey]: newEditorState,
    })
    if (translate){
            setTranslates({...translate, description: newEditorState})
     
  }
}
  return (
    <>
      <div
        className='modal fade'
        id={'modalupdatecategory' + slug}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Modifier Categorie
              </h5>
              <button
                type='button'
                className='btn close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true' onClick={() => setLoading(false)}>
                  &times;
                </span>
              </button>
            </div>
            <div
              className='modal-body'
              style={{height: ' 307px', overflow: 'hidden', overflowY: 'scroll'}}
            >
              <form>
                <label htmlFor='exampleInputEmail1'>Photo :</label>
                <div className='form-group mb-5 row  '>
                  <div className='col-4'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
                      }}
                    >
                      <div className='d-flex'>
                        {file ? (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                            }}
                          ></div>
                        ) : (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px mr-2 '
                            style={{
                              backgroundImage:
                                "url('https://api.nanogiga.fcpo.agency/" +
                                categorie.imageurl +
                                "')",
                            }}
                          ></div>
                        )}
                      </div>

                      <label
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {files} = e.target
                            if (files) {
                              setFile(files[0])
                            }
                          }}
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          multiple
                        />

                        <input type='hidden' name='avatar_remove' />
                      </label>

                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                  </div>

                  <div className='col-8'>
                    {' '}
                    <DropDownLanguage
                      addlang={false}
                      langs={categorie.translates.map((translation: any) => translation.lang)}
                      selectedLang={[lang, setLang]}
                    />
                  </div>
                </div>
                <div className='form-group  mb-5'>
                  <label htmlFor='exampleInputEmail1'>Categorie :</label>
                  <div className='d-flex justify-content-start flex-column'>
           
                    <input
                      type='text'
                      className='form-control'
                      id='exampleInputEmail1'
                      name='partenaire'
                      aria-describedby='text'
                         required
                         value={translate ? decodeHtmlTags( translate.categorie) : decodeHtmlTags(category)}
                         onChange={(e: any) => {
                        setCategory(e.target.value)
                        if (translate) {
                          setTranslates({...translate, categorie: e.target.value})
                        }
                      }}
                    />
                  </div>{' '}
                </div>
                <div className='form-group  mb-5'>
                  <label htmlFor='exampleInputEmail1'>Description :</label>
                  <div className='d-flex justify-content-start flex-column'>
                  <EditorQuell value={{  onEditorStateChange}} title={"description"} data={translate ? decodeHtmlTags( translate.description): decodeHtmlTags(editorContent.description)}/>

                    {/* <textarea
                      rows={3}
                      className='form-control'
                      id='exampleInputEmail1'
                      name='partenaire'
                      aria-describedby='text'
                      placeholder='lien'
                      required
                      value={translate ? translate.description : description}
                      onChange={(e: any) => {
                        setDescription(e.target.value)
                        if (translate) {
                          setTranslates({...translate, description: e.target.value})
                        }
                      }}
                    /> */}
                  </div>{' '}
                </div>

                <div className='form-group'>
                  <label htmlFor='exampleInputEmail1'>Parent :</label>
                  <div className='d-flex justify-content-start flex-column'>
                    <select
                      onChange={handleCategoryChange}
                      className='form-select'
                      id='exampleInputEmail1'
                      name='partenaire'
                    >
                      <option value=''>Choisir..</option>
                      {categories.map((c: any, index) => {
                        return (
                          <option
                            key={index}
                            value={c.id}
                            selected={categorie.parent && c.id === categorie.parent.id}
                          >
                            {c.categorie}
                          </option>
                        )
                      })}
                    </select>
                  </div>{' '}
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                onClick={() => setLoading(false)}
              >
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' className='btn btn-primary' onClick={updateCategory}>
                  Modifier
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
