import React, { useEffect, useState } from 'react'
import { FormAddSubProduct } from '../../../components/componentsProduit/FormAddSubProduct'
import { useCallApi } from '../../modules/utils';
import { useParams } from 'react-router-dom';
import { ListSubProduct } from '../../../components/componentsProduit/ListSubProduct';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

export const AddSubProduct = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Produit",
      path: "/products/all",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Ajouter Sous-Produit",
      path: "#",
      isSeparator: true,
      isActive: true,
    },
  ];
  const {slug}=useParams();
  const callApi = useCallApi();
  const [details, setDetails] = useState([])

   const getDetails=async()=>{
    const {data}  = await callApi({
    route: `api/produits/`+slug,
    method: "GET",
  });

   setDetails(data.details)
   
  }
  console.log(details);
  
  useEffect(() => {
    getDetails()
  
  
  }, [])
  return (
    <div>
         <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter Sous-Produit </PageTitle>
         <FormAddSubProduct/>
         <ListSubProduct details={details}/>  
    </div>
  )
}
