import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import {createFormData, useCallApi} from '../../app/modules/utils'
import {AnyObjectSchema} from 'yup'
import { Loading } from '../Loading'



type Props = {
   
    title: string
    avatar: string
   id:number
   link:string
  }

const ModalPartenaireUpdate: React.FC<Props> = ({ link,title, avatar,id}) => {
  
  const callApi = useCallApi()
  const [file, setFile] = useState<File | any>(null)
   const [linkk, setLink] = useState(link)
  const [partenaire, setPartnaire] = useState(title)
 const [loading, setLoading] = useState(false)

  const updatePartner = async() => {
    setLoading(true)
    try {
      const dataTosend = {
        partenaire:id,
        image: file,
        title: partenaire,
        link:linkk,
      }
      console.log(dataTosend);
      
      const formData = createFormData(dataTosend);  
      const data  = await callApi({
      route: `api/partenaires/`+id,
      method: "POST",
      body:formData
    
    });
    toast.success("Modifié ")
    setLoading(false)
    } catch (error:any) {
      setLoading(false)
      toast.error("Eroor updating : "+error?.response.data.detail)

    }
    
  }
  return (
    <>
      <div
        className='modal fade'
        id={'modalupdatepartner'+id}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Modifier Partenaire
              </h5>
              <button
                type='button'
                className='btn close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form >
                <label htmlFor='exampleInputEmail1'>Photo :</label>
                <div className="form-group mb-5  ">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        "url(/assets/media/svg/avatars/blank.svg)",
                    }}
                  >
                    <div className="d-flex">
                      {file 
                        ? 
                           
                             <div
                                id="image"
                                className="image-input-wrapper w-125px h-125px"
                                style={{
                                  backgroundImage:
                                    "url(" + URL.createObjectURL(file) + ")",
                                }}
                              ></div>
                         
                        : 
                           
                             <div
                             
                                id="image"
                                className="image-input-wrapper w-125px h-125px mr-2 "
                                style={{
                                  backgroundImage:
                                    "url('https://api.nanogiga.fcpo.agency/" +
                                    avatar +
                                    "')",
                                }}
                              ></div>
                              }
                    </div>

                    <label
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>

                      <input
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const { files } = e.target;
                          if (files) {
                            setFile(files[0]);
                          }
                        }}
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        multiple
                      />

                      <input type="hidden" name="avatar_remove" />
                    </label>

                    <span
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Cancel avatar"
                    >
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='exampleInputEmail1'>Partenaire :</label>
                  <div className='d-flex justify-content-start flex-column'>
                    <input
                      type="text" className="form-control" id="exampleInputEmail1" name="partenaire"aria-describedby="text" placeholder="partenaire" required
                    
                      value={partenaire}
                      onChange={(e: any) => {
                        setPartnaire(e.target.value)
                      }}
                    />
                  </div>{' '}
                </div>
                <div className='form-group'>
                  <label htmlFor='exampleInputEmail1'>Lien :</label>
                  <div className='d-flex justify-content-start flex-column'>
                    <input
                      type="text" className="form-control" id="exampleInputEmail1" name="partenaire"aria-describedby="text" placeholder="lien" required
                   
                      value={linkk}
                      onChange={(e: any) => {
                        setLink(e.target.value)
                      }}
                    />
                  </div>{' '}
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {
                loading? <button type='button' className='btn btn-primary'  >
                <Loading/>
              </button>: <button type='button' className='btn btn-primary' onClick={updatePartner} >
                Modifier
              </button>
              }
             
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ModalPartenaireUpdate
