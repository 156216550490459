import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import {createFormData, useCallApi} from '../../app/modules/utils'
import {AnyObjectSchema} from 'yup'
import {Loading} from '../Loading'
import {DropDownLanguage} from '../componentsProduit/DropDownLanguage'

type Props = {
  title: string
  avatar: string
  slug: string
  description: string
  translates: []
}

const ModalUpdatePosts: React.FC<Props> = ({description, title, avatar, slug, translates}) => {
  const callApi = useCallApi()
  const [file, setFile] = useState<File | any>(null)
  const [titlee, setTitlee] = useState(title)
  const [descriptionn, setDescriptionn] = useState(description)
  const [loading, setLoading] = useState(false)
  const [translate, setTranslate] = useState<any>()

  const [lang, setLang] = useState({
    name: '',
    lang: 'en',
    img: '',
  })

  const updatePost = async () => {
  
    setLoading(true)

    if (lang.lang == 'en') {
      try {
        const dataTosend = {
          image:file,
          title: titlee,
          description: descriptionn,
        }
        const formData = createFormData(dataTosend)
        const {data, status} = await callApi({
          route: `api/posts/` + slug,
          method: 'POST',
          body: formData,
        })
        toast.success("poste modifié")
        setLoading(false)

      } catch (error:any) {
        toast.error(error.response.data.detail)
        setLoading(false)
      }
          
        
    } else {
      try {
        const dataTosend = {
          title: titlee,
          description: descriptionn,
        }
        const {data, status} = await callApi({
          route: `api/translate_posts/` + translate.id,
          method: 'PUT',
          body: dataTosend,
        })
        toast.success("poste modifié")
        setLoading(false)

      } catch (error) {
        toast.error("une erreur s'est produite ! ")
        setLoading(false)
      }
          
          
    }
  }
  useEffect(() => {
    setTranslate(translates.find((translate: any) => translate.lang === lang.lang))
  }, [lang])

  console.log(translate)

  return (
    <>
      <div
        className='modal fade'
        id={'modalupdatepost' + slug}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Modifier Poste
              </h5>
              <button
                type='button'
                className='btn close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div
              className='modal-body'
              style={{height: ' 357px', overflow: 'hidden', overflowY: 'scroll'}}
            >
              <form>
                <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                  Photo :
                </label>

                <div className='form-group mb-5  row  '>
                  <div className='col-4'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
                      }}
                    >
                      <div className='d-flex'>
                        {file ? (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                            }}
                          ></div>
                        ) : (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px mr-2 '
                            style={{
                              backgroundImage:
                                "url('https://api.nanogiga.fcpo.agency/" + avatar + "')",
                            }}
                          ></div>
                        )}
                      </div>

                      <label
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {files} = e.target
                            if (files) {
                              setFile(files[0])
                            }
                          }}
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          multiple
                        />

                        <input type='hidden' name='avatar_remove' />
                      </label>

                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                  </div>
                  <div className='col-8'>
                    <DropDownLanguage
                      addlang={false}
                      langs={translates.map((translation: any) => translation.lang)}
                      selectedLang={[lang, setLang]}
                    />
                  </div>
                </div>
                <div className='form-group mb-5'>
                  <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                    Titre :
                  </label>
                  <div className='d-flex justify-content-start flex-column'>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleInputEmail1'
                      name='partenaire'
                      aria-describedby='text'
                      placeholder='partenaire'
                      required
                      value={translate ? translate.title : titlee}
                      onChange={(e: any) => {
                        
                        setTitlee(e.target.value)
                        if(translate){
                          setTranslate({...translate, title: e.target.value})
                        }
                      }}
                    />
                  </div>{' '}
                </div>
                <div className='form-group mb-5'>
                  <label className='fw-bold fs-6' htmlFor='exampleInputEmail1'>
                    Description :
                  </label>
                  <div className='d-flex justify-content-start flex-column'>
                    <textarea
                      rows={4}
                      className='form-control'
                      id='exampleInputEmail1'
                      name='partenaire'
                      aria-describedby='text'
                      placeholder='lien'
                      required
                      value={translate ? translate.description : descriptionn}
                      onChange={(e: any) => {
                        setDescriptionn(e.target.value)
                        if(translate){
                          setTranslate({...translate, description: e.target.value})
                        }
                      }}
                    />
                  </div>{' '}
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary' >
                  <Loading />
                </button>
              ) : (
                <button type='button' className='btn btn-primary' onClick={updatePost}>
                  Modifier
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ModalUpdatePosts
