/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'
import { ModalUpdateSlider } from './ModalUpdateSlider'
import { DropDownSlider } from './DropDownSlider'
import { decodeHtmlTags } from '../../app/modules/utils'


// import ModalUpdateCategory from './ModalUpdateCategory'


type Props = {
  categorie:any,
  deleteSlider:(id:number)=>void,
  getsliders:()=>void
}

const ItemSlider: React.FC<Props> = ({categorie,deleteSlider,getsliders}) => {
  console.log(categorie);

  return (
    <div className={`card `}>
      {/* begin::Body */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bold text-dark'>Tasks Overview</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Pending 10 tasks</span> */}
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold"
            role="button" id="dropdownMenuLnk"
            data-bs-toggle="dropdown"
             aria-expanded="false"

          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <ModalUpdateSlider getsliders={getsliders}categorie={categorie}/>
          <DropDownSlider  deleteSlider={deleteSlider} id={categorie.id}/>

          {/* end::Menu */}
        </div>
      </div>
      <div className='card-body  align-items-center pt-3 pb-0'>
        

        <img src={"https://api.nanogiga.fcpo.agency/"+categorie.image} alt='' className='align-self-end  h-100px rounded' />
  
        {
           
           <div className=' align-items-center mb-5'
              dangerouslySetInnerHTML={{__html: decodeHtmlTags( categorie?.comment)}}
>
            {}
       
           
         
       
         </div>
        }
        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ItemSlider}
