import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props={
  slug:string,
  id:number
  deleteProduct:(slug:string)=>void
}
export const DropDownProduct:React.FC<Props> = ({deleteProduct,slug,id}) => {
  return (
    <div className="btn-group">
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold"role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
        
        >
          <KTSVG
            path="/media/icons/duotune/general/gen024.svg"
            className="svg-icon-2"
          />
        </button>
        <div className="dropdown-menu"aria-labelledby="dropdownMenuLink">
          <Link
            title={"modifier"}
            to={"/pages/managementProducts/update-product/"+slug }
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
            <span> modidfier la catégorie</span>
          </Link>
         
          
          <Link
            title={"ajouter details pour image"}
            to={"/pages/managementProducts/add-sub-product/"+slug }
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen035.svg"
              className="svg-icon-2"
            />
            <span>ajouter détails </span>
          </Link>
          <Link
            title={"ajouter details pour image"}
              to={"/pages/managementProducts/add-lang-product/"+slug }
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/general/language.svg"
              className="svg-icon-2"
            />
            <span>nouvelle version de langue </span>
          </Link>
          <Link
            to={"#"}
            title={"supprimer"}
            className="dropdown-item p-2 text-hover-primary"
            onClick={() => deleteProduct(slug)}
          >
            <KTSVG
              // امين
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            
            />
            <span > supprimer la catégorie</span>
          </Link>
        </div>
      </div>
  )
}
