import axios from "axios";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Header } from "../../../_metronic/layout/components/header/Header";
import ModalAddCategory from "../../../components/componentCategory/ModalAddCategory";
import { Loading } from "../../../components/Loading";
import ListCategory from "../../../components/componentCategory/ListCategory";



const ListeCategories=() => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Catégories",
      path: "/categories/all",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Header />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Catégories </PageTitle>
      <ListCategory className=""/>
    </div>
  );
};

export default ListeCategories;
