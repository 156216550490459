import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import clsx from 'clsx'
import {setLanguage} from '../../_metronic/i18n/Metronici18n'

const languages = [

  {
    lang: 'zh',
    name: 'Mandarin',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]
type Props = {
  selectedLang: any
  langs?: any
  addlang:boolean
}

export const DropDownLanguage: React.FC<Props> = ({addlang,selectedLang, langs}) => {
 

  return (
    <>
      <div className='dropdown  btn-muted mb-1'>
        <button
          className='btn  w-50 dropdown-toggle'
          style={{
            background: '#cacacc36',
            color: 'rgb(139 142 153)',
          }}
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <span className='menu-title position-relative'>
            Langue
            {selectedLang[0].img && selectedLang[0].name && (
              <span className='fs-8 rounded bg-white px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
                {selectedLang[0].name}
                <img
                  className='w-15px h-15px rounded-1 ms-2'
                  src={selectedLang[0].img}
                  alt='metronic'
                />{' '}
              </span>
            )}
          </span>
        </button>
        <ul className='dropdown-menu ' aria-labelledby='dropdownMenuButton1'>
         
          {langs
            ? languages
                .filter((lang) => langs?.includes(lang.lang))
                .map((l: any) => (
                  <div
                    className='menu-item px-3'
                    key={l.lang}
                    onClick={() => {
                      selectedLang[1]({...selectedLang[0], lang: l.lang, name: l.name, img: l.flag})
                    }}
                  >
                    <a className={'menu-link d-flex px-5'}>
                      <span className='symbol symbol-20px me-4'>
                        <img className='rounded-1' src={l.flag} alt='metronic' />
                      </span>
                      {l.name}
                    </a>
                  </div>
                ))
            : languages.map((l: any) => (
                <div
                  className='menu-item px-3'
                  key={l.lang}
                  onClick={() => {
                    selectedLang[1]({...selectedLang[0], lang: l.lang, name: l.name, img: l.flag})
                  }}
                >
                  <a  className={'menu-link d-flex px-5'}>
                    <span className='symbol symbol-20px me-4'>
                      <img className='rounded-1' src={l.flag} alt='metronic' />
                    </span>
                    {l.name}
                  </a>
                </div>
              ))}
              {
                addlang?<></>: <div
                  className='menu-item px-3'
                  onClick={() => {
                    selectedLang[1]({...selectedLang[0], lang: 'en', name:'English', img: toAbsoluteUrl('/media/flags/united-states.svg')})
                  }}
                >
                  <a  className={'menu-link d-flex px-5'}>
                    <span className='symbol symbol-20px me-4'>
                      <img className='rounded-1' src={toAbsoluteUrl('/media/flags/united-states.svg')} alt='metronic' />
                    </span>
                    {"English"}
                  </a>
                </div>
              }
          
        </ul>
      </div>
    </>
  )
}
