import React from 'react'
import { ListPosts } from "../../../components/componentsPost/ListPosts";
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Header } from '../../../_metronic/layout/components/header/Header';

export const PageListPosts = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Postes",
      path: "#",
      isSeparator: false,
      isActive: false,
    },
  ];
  return (
    <>
    <Header />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Postes </PageTitle>
    <ListPosts/>
    </>
  )
}
