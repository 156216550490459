import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers'

type Props = {
    estimate: any
  }
const ItemEstimates : React.FC<Props> = ({estimate}) => {
    console.log(estimate.produitDevis);
    
  return (
    <>
    <tr>  <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 ml-5 fs-6'>
                   {estimate.date.split("T")[0]}
                  </a>
                </td>
              <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                       {estimate.client.nom} {estimate.client.prenom}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                       {estimate.client.tel}
                      </span>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                       {estimate.client.email}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src={"https://api.nanogiga.fcpo.agency"+estimate.produitDevis[0]?.produit?.imageurl}
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {estimate.produitDevis[0]?.produit?.titre}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center' >
                  {estimate.produitDevis[0]?.quantite}

                  </a>
                </td>
              
               
                <td>
                  <span className='badge badge-light-primary fs-7 fw-semibold'>Approved</span>
                </td>
                {/* <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </a>
                  <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </a>
                </td> */}
              </tr>
    </>
  )
}

export default ItemEstimates