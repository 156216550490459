import axios from "axios";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Header } from "../../../_metronic/layout/components/header/Header";
import { ListePartenaires } from "../../../components/componentsPartners/ListPartners";




const ListePartenaitres=() => {
  
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Partenaires",
      path: "#",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <div>
      <Header />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Partenaires </PageTitle>
      <ListePartenaires/>
    </div>
  );
};

export default ListePartenaitres;
