import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { useParams } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  createFormData, useCallApi } from '../../app/modules/utils';
import { ItemSubProduct } from './ItemSubProduct';
import { toast } from 'react-toastify';

type Props={
  details:any
}

export const ListSubProduct:React.FC<Props>  = ({details}) => {
  const [detailss, setDetails] = useState(details)

  
  const callApi = useCallApi();
  const deleteSubProduct = async (id: number) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce sous-produit !')) {
      const filteredpartners = details.filter((detail: any) => detail.id !== id)
      setDetails(filteredpartners) 
        toast.success("partenaire supprimé")
        const {data} = await callApi({
        route: 'api/details/' + id,
        method: 'DELETE',
      })
   
    
    }
  }
  console.log(detailss);
   useEffect(() => {
    setDetails(details)
   }, [details])
   
  
  return (
    <>
        {
          detailss.map((detail:any,index:number)=>{
            return <ItemSubProduct deleteSubProduct={deleteSubProduct} detail={detail}  id={detail.id}/>
          })
        }
    </>
  )
}
