import axios from 'axios'
import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import './css/mystyle.css'
import {EditorState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {createFormData, encodeHtmlTags, useCallApi} from '../../app/modules/utils'
import {DropDownCategorie} from '../DropDownCategorie'
import {Loading} from '../Loading'
import EditorQuell from './EditorQuell'

interface EditorContent {
  application: any
  caracteristique: any
  specification: any
  produits:any
}

export const FormAddProduct = () => {
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>(null)
  const [pdf, setPdf] = useState<File | any>(null)
  const [file, setFile] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [selectSubCategorie, setSelectSubCategorie] = useState()
 
  const [editorContent, setEditorContent] = useState<any>({
    application: "",
    caracteristique: "",
    specification: "",
    produits:""
  })
 

  const initialValues: any = {
    titre: '',
    description: '',
    avantages: '',
    categorie: 0,
  }

  const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
    setEditorContent({
      ...editorContent,
      [editorKey]: newEditorState,
    })
  }

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true)
      const dataTosend = {
        image: image,
        pdf: pdf,
        categorie: selectSubCategorie,
        titre: values.titre,
        description: values.description,
        avantages: values.avantages,
        applications: encodeHtmlTags(editorContent.application) ,
        caracteristiques: encodeHtmlTags(editorContent.caracteristique),
        specifications: encodeHtmlTags(editorContent.specification),
        produits:encodeHtmlTags(editorContent.produits)
      }
      const formData = createFormData(dataTosend)
      const data = await callApi({
        route: `api/produits`,
        method: 'POST',
        body: formData,
      })
      setLoading(false)
      
      toast.success('ajouté ')  
      setFile("")
      setImage(null)
      console.log(data)
    } catch (error: any) {
      toast.error(error.response.data.detail)
      setLoading(false)
    }
  }
  
  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        onSubmit={(values,{resetForm}) => {
          handleSubmit(values)
          resetForm()
         
        

        }}
      >
        {() => (
          <>
            <Form className='mx-5'>
              <label className=' fw-bold fs-6' htmlFor='exampleInputEmail1'>
                Photo :
              </label>
              <div className='form-group mb-5  '>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                >
                  <div
                    id='image'
                    className='image-input-wrapper w-125px h-125px'
                    style={{backgroundImage: file === '' ? "url('')" : 'url(' + file + ')'}}
                  ></div>
                  <label
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // const reader = new FileReader();
                        // reader.onload=function(e){
                        //   setUrl(e.target?.result)
                        // }
                        // reader.readAsDataURL(e.target.files[0])
                        if (e.target.files && e.target.files[0]) {
                          setFile(URL.createObjectURL(e.target.files[0]))
                          setImage(e.target.files[0])
                        }
                      }}
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>

                  <span
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Cancel avatar'
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>
              </div>
              <div className='form-group mb-5  '>
                <label className='mb-1  fw-bold fs-6' htmlFor='titre'>
                  Titre :
                </label>
                <Field
                  type='text'
                  className='form-control'
                  name='titre'
                  id='titre'
                  placeholder='Titre'
                />
              </div>
              <div className='form-group mb-5 row'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Categorie :
                </label>
                <DropDownCategorie
                  action='add'
                  setSelectSubCategorie={setSelectSubCategorie}
                  categorieId={0}
                  subcategorieId={0}
                />
              </div>

              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Description :
                </label>
                <Field
                  as='textarea'
                  className='form-control'
                  name='description'
                  id='exampleFormControlTextarea1'
                  rows={4}
                  placeholder='Description'
                />
              </div>
              <div className='form-group mb-5  '>
                <label className='mb-1  fw-bold fs-6' htmlFor='titre'>
                  Avantages :
                </label>
                <Field
                  as='textarea'
                  rows={4}
                  className='form-control'
                  name='avantages'
                  id='avantage'
                  placeholder='Avantages'
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Specifications :
                </label>
              
                <EditorQuell value={{  onEditorStateChange}} title={"specification"}/>
              </div>

              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Caracteristiques :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"caracteristique"}  />

              </div>

              <div className='mb-3'>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  PDF :
                </label>
                <br></br>
                <div>
                  {' '}
                  <Button variant='contained' component='label'>
                    Votre fichier PDF
                    <input
                      hidden
                      accept='application/pdf'
                      type='file'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files && e.target.files[0]) {
                          console.log(e.target.files[0].name)

                          setPdf(e.target.files[0])
                        }
                      }}
                    />
                  </Button>
                  {pdf && pdf.name}
                </div>
              </div>

              <div className='mb-3 ' style={{}}>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Applications :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"application"}/>

              </div>
              <div className='mb-3 ' style={{}}>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Liste des Produits :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"produits"}/>

              </div>

              <div className='d-flex justify-content-end '>
                {loading ? (
                  <button className='btn btn-primary text-end'>
                    <Loading />
                  </button>
                ) : (
                  <button type='submit' className='btn btn-primary text-end'>
                    Ajouter
                  </button>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}
