import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { createFormData, useCallApi } from '../../app/modules/utils';
import { DropDownLanguage } from '../componentsProduit/DropDownLanguage';



type Props = {
   
    slug:string
  }


const ModalAddLang: React.FC<Props> = ({slug})  => {
  const callApi = useCallApi();
	const [image, setImage] = useState<File | any>(null);
  const [file,setFile]=useState<string>("");
  const [categories, setCategories] = useState([])
  const [idCategorie, setIdCategorie] = useState()

  const [lang, setLang] = useState({
    name:"",
    lang:"",
    img:""
})
  const initialValues = {
    description:"",
    title: "",
  };

	const handelSumit=async(values:any)=>{
	const dataTosend = {
             post:"api/posts/"+slug,
             title: values.title,
              description:values.description,
              lang:lang.lang
    };
    
    console.log(dataTosend);
    
    const {data,status}  = await callApi({
    route: `api/translate_posts`,
    method: "POST",
    body:dataTosend
     }); 
     if(status==201){
          toast.success("ajouté")
     }else{
        toast.error("une erreur s'est produite")
     }
   

	}
 

  
  return (
    <>
<div className="modal fade" id={"modaladdlangpost"+slug} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">


    <Formik
      initialValues={initialValues}
      onSubmit={(values,{resetForm})=>{
        handelSumit(values) 
        }}>
        {()=>( 
        <Form className="modal-content" >
        <div className="modal-header">
          <h5 className="modal-title " id="exampleModalLongTitle">Ajouter une version langue  </h5>
          <button type="button" className=" btn close" data-bs-dismiss="modal" aria-bs-label="Close">
            <span aria-bs-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
            <form>
            <div className='form-group mb-5'>
            <DropDownLanguage addlang={true} selectedLang={[lang,setLang]}/>
            </div>
              <div className="form-group mb-5 ">
                <label className='fw-bold fs-6' htmlFor="exampleInputEmail1">Titre :</label>
                <Field type="text" className="form-control" id="exampleInputEmail1" name="title"aria-describedby="text" placeholder="Poste" required/>
              </div>
           
              <div className="form-group mb-5">
              <label className='fw-bold fs-6' htmlFor="exampleInputEmail1">Description :</label>
                <Field as="textarea"  row={5} className="form-control" id="exampleInputEmail1" name="description"aria-describedby="text" placeholder="Desription" required/>
                </div>

      
      </form>



        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
          <button type="submit" className="btn btn-primary">Ajouter</button>
        </div>
              
        </Form>)}
    </Formik>
  </div>
</div>
</>
  ) 
}

export default ModalAddLang