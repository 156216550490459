/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../_metronic/helpers'

type Props = {
   
    deletePartenaire:(slug:number)=>void
    id:number
  }
export const DropDownPartner: React.FC<Props> = ({deletePartenaire,id})=> {
  return (
    <div className="dropdown-menu"aria-labelledby="dropdownMenuLink">
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'> Actions</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className=''>

          <Link
            title={"modifier"}
            to={"" }
            className="dropdown-item p-2  text-muted "
            data-bs-toggle='modal'
            data-bs-target={'#modalupdatepartner'+id}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
                      <label className='form-label fw-bold  text-muted'>modifier le partenaire</label>
          </Link>
        </div>
        <div className=''>
        <Link
            to={"#"}
            title={"supprimer"}
            className="dropdown-item p-2 text-muted"
            onClick={() => deletePartenaire(id)}
          >
            <KTSVG
              // امين
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3" 
              
            
            />
            <span className='form-label fw-bold text-muted'> supprimer le partenaire</span>
          </Link>
</div>

        {/* <div className='mb-10'>
          <label className='form-label fw-bold'>Member Type:</label>

          <div className='d-flex'>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='1' />
              <span className='form-check-label'>{id}</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
              <span className='form-check-label'>Customer</span>
            </label>
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Notifications:</label>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
            />
            <label className='form-check-label'>Enabled</label>
          </div>
        </div> */}

       
      </div>
    </div>
  )
}
