import React, { useEffect, useState } from 'react'
import { showToast, useCallApi } from '../../app/modules/utils'
import { ToastContainer, toast } from 'react-toastify'
import { KTSVG, useDebounce } from '../../_metronic/helpers'
import { Loading } from '../Loading'
import ModalAddCategory from '../componentCategory/ModalAddCategory'
import ModalPartenaire from './ModalPartner'
import SearchComponent from '../SearchComponent'
import { StatisticsWidget2 } from '../../_metronic/partials/widgets'
import { ItemPartenaire } from './ItemPartner'

export const ListePartenaires = () => {
    const [partners, setPartners] = useState([])
    const [file, setFile] = useState<string>('')
    const [loading, setLoading] = useState(true)
    const callApi = useCallApi()
    const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
    const getPartners = async () => {
      const {data} = await callApi({
        route: 'api/partenaires',
        method: 'GET',
      })
      setPartners(data)
      setLoading(false)
    }

    useEffect(() => {
      getPartners()
    }, [])
  
    const deletePartenaire = async (id: number) => {
      if (window.confirm('Voulez-vous vraiment supprimer ce partenaire !')) {
        const filteredpartners = partners.filter((partenr: any) => partenr.id !== id)
          setPartners(filteredpartners) 
          toast.success("partenaire supprimé")
          const {data} = await callApi({
          route: 'api/partenaires/' + id,
          method: 'DELETE',
        })
     
      
      }
    }
    const getPartenaireByName= async ()=>{
      setLoading(true)
      const {data}= await callApi({
          route: 'api/partenaires?title='+debouncedSearchTerm,
          method: "GET",
        });
        setPartners(data)
        setLoading(false)
  }

    
    useEffect(() => {
      getPartenaireByName()
    }, [debouncedSearchTerm])

  return (
    <div>
    <ToastContainer />
    <div className={`card  mb-5 `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
       {/*  <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <SearchInput searching={searching} placeholder="Catégoeie"/>
          </span>
        </h3> */}
        <SearchComponent action="add"placeholder={"Recherche Partenaire"} searchQuery={[searchTerm,setSearchTerm]} />

        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            type='button'
            className='btn btn-sm btn-primary '
            data-bs-toggle='modal'
            data-bs-target='#modalpartner'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
            Nouvelle Partenaire
          </button>

          <ModalPartenaire />
        </div>
      </div>
    </div>
      {loading ? (
        <Loading />
      ) : (
      
        <div className='row g-3 g-xl-3'>
          {
            partners.map((partner:any, index) => {
              return (
                 <div className='col-xl-4'>
                  <ItemPartenaire  link={partner.link} key={index}deletePartenaire={deletePartenaire} id={partner.id} className='card-xl-stretch mb-xl-8' description={''} title={partner.title} avatar={partner.imageurl}/>
                </div> 
                // <ItemPartenaire
                //   deletePartenaire={deletePartenaire}
                //   key={index}
                //   data={partner}
                //   className=''
                // />
              )
            })
          }
          </div>
             
      )}
      {/* end::Header */}
      {/* begin::Body */}

      {/* begin::Body */}
  
  </div>
  )
}

