import React, { ChangeEvent, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import './css/mystyle.css'
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  createFormData, encodeHtmlTags, useCallApi } from '../../app/modules/utils';
import { useParams } from 'react-router-dom';
import { DropDownLanguage } from './DropDownLanguage';
import EditorQuell from './EditorQuell';

type Props={
    id:number
}

interface EditorContent {
    specification: EditorState;
 
}
export const ModalAddLangSubProduct:React.FC<Props>  = ({id}) => {
    const callApi = useCallApi();

    const [lang, setLang] = useState({
        name:"",
        lang:"en",
        img:""
    })
    
    const [editorContent, setEditorContent] = useState<EditorContent>({
        specification: EditorState.createEmpty(),
  
      });
   
    
      const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
        setEditorContent({
          ...editorContent,
          [editorKey]: newEditorState,
        });
      };
    
    
      
      const handleSubmit = async(e:any) => {
        e.preventDefault()
  
        try {
          const dataTosend = {
            details:"api/details/"+id,
            lang:lang.lang,
            specification:encodeHtmlTags(draftToHtml(convertToRaw(editorContent.specification.getCurrentContent()))),
          };
          
         
          const data  = await callApi({
          route: `api/translate_details`,
          method: "POST",
          body:dataTosend
        });
        toast.success("ajouté ")
        
        } catch (error:any) {
          toast.error("Error adding  : "+error?.response.data.detail)
             console.log(error?.response.data.detail);
             
        }
        
    
    
      
    
    }
  
  return (
    <>
    <div className="modal fade" id={"modaladdlangsubproduct"+id} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div className="modal-dialog" role="document">
      
        <div className="modal-content" >
          <div className="modal-header">
          <h5 className="modal-title " id="exampleModalLongTitle">Ajouter une version langue  </h5>
          <button type="button" className=" btn close" data-bs-dismiss="modal" aria-bs-label="Close">
            <span aria-bs-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className='mx-5' onSubmit={handleSubmit}>
            <div className="mb-5">
            <DropDownLanguage addlang={true} selectedLang={[lang,setLang]}/>

            </div>
            <div className='mb-3'>
              <label
                htmlFor='exampleFormControlTextarea1'
                className='form-label  fw-bold fs-6'
              >
                Specification :
              </label>
              <EditorQuell  value={{  onEditorStateChange}} title={"specification"}/>

              {/* <Editor
              editorClassName='bg-secondary  ml-5'
              editorState={editorContent.specification}
              onEditorStateChange={(newEditorState) => onEditorStateChange('specification', newEditorState)}
            /> */}
            </div>

          

            <div className='d-flex justify-content-end mb-4'>
              <button type='submit' className='btn btn-primary text-end'>
                Ajouter
              </button>
            </div>
          </form>
          </div>
        </div>
        
      </div>
    </div>

    </>
  )
}
