import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {createFormData, decodeHtmlTags, encodeHtmlTags, useCallApi} from '../../app/modules/utils'
import {log} from 'console'
import {toast} from 'react-toastify'
import {Loading} from '../Loading'
import {DropDownLanguage} from '../componentsProduit/DropDownLanguage'
import EditorQuell from '../componentsProduit/EditorQuell'
import { EditorState } from 'draft-js'


type Props = {
  categorie: any
  getsliders:()=>void
}
interface EditorContent {
 
  comment: any
}
export const ModalUpdateSlider: React.FC<Props> = ({categorie,getsliders}) => {
    
  const callApi = useCallApi()
  const [file, setFile] = useState<File | any>(null)
  const closeM=useRef<any>()
  const [comment, setComment] = useState<string>(categorie.comment)

  const [slug, setSlug] = useState(categorie.id)

  const [loading, setLoading] = useState(false)
  const [editorContent, setEditorContent] = useState<any>({
    comment:""
  })
  const updateCategory = async () => {
    setLoading(true)
    const dataTosend = {
      id: categorie.id,
      image: file,
      comment: encodeHtmlTags(editorContent.comment),
    }

    const formData = createFormData(dataTosend)
     setLoading(true)
   
    try {
        console.log(dataTosend);
              const {data, status} = await callApi({
            route: `api/sliderupdate/` + categorie.id,
            method: 'POST',
            body: formData,
          })
      
        
        
         
          
        
        setLoading(false)
        if(closeM.current){
            closeM.current.click()
        }
        toast.success("modifiée")
        getsliders()
    } catch (error:any) {
      setLoading(false)
      toast.error("Error updating : "+error?.response.data.detail)
    }
 

   
  }
//   const handleCategoryChange = async (event: any) => {
//     setIdCategorie(event.target.value)
//   }
//   const getsliderss = async () => {
//     const {data} = await callApi({
//       route: 'api/all',
//       method: 'GET',
//     })
//     setCategories(data)
//     console.log(categories)
//   }
//   useEffect(() => {
//     getsliderss()
//   }, [])

//   useEffect(() => {
//     setTranslates(categorie.translates.find((translate: any) => translate.lang === lang.lang))
//   }, [lang])
//   console.log(translate)


useEffect(() => {
    
  setEditorContent({
   
    comment: decodeHtmlTags(comment)


   })
  
}, [categorie])
 
const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
  setEditorContent({
    ...editorContent,
    [editorKey]: newEditorState,
  })
 
}
  // const handelUpdate=(value:any)=>{
  //       setComment(value)
        
  // }
  return (
    <>
      <div
        className='modal fade'
        id={'modalupdateslide' + slug}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <form className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Modifier Categorie
              </h5>
              <button
                type='button'
                className='btn close'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true' onClick={() => setLoading(false)}>
                  &times;
                </span>
              </button>
            </div>
            <div
              className='modal-body'
              style={{height: ' 307px', overflow: 'hidden', overflowY: 'scroll'}}
            >
              <form>
                <label htmlFor='exampleInputEmail1'>Photo :</label>
                <div className='form-group mb-5 row  '>
                  <div className='col-4'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
                      }}
                    >
                      <div className='d-flex'>
                        {file ? (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                            }}
                          ></div>
                        ) : (
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px mr-2 '
                            style={{
                              backgroundImage:
                                "url('https://api.nanogiga.fcpo.agency/" +
                                categorie.image +
                                "')",
                            }}
                          ></div>
                        )}
                      </div>

                      <label
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Change avatar'
                      >
                        <i className='bi bi-pencil-fill fs-7'></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {files} = e.target
                            if (files) {
                              setFile(files[0])
                            }
                          }}
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          multiple
                        />

                        <input type='hidden' name='avatar_remove' />
                      </label>

                      <span
                        className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='cancel'
                        data-bs-toggle='tooltip'
                        data-bs-dismiss='click'
                        title='Cancel avatar'
                      >
                        <i className='bi bi-x fs-2'></i>
                      </span>
                    </div>
                  </div>

                 
                </div>
                
                <div className='form-group  mb-5'>
                  <label htmlFor='exampleInputEmail1'>comment :</label>
                  <div className='d-flex justify-content-start flex-column'>
                  <EditorQuell value={{onEditorStateChange}} title={"comment"} data={editorContent.comment}/>

                    {/* <textarea
                      rows={3}
                      className='form-control'
                      id='exampleInputEmail1'
                      name='partenaire'
                      aria-describedby='text'
                      placeholder='lien'
                      required
                      value={translate ? translate.comment : comment}
                      onChange={(e: any) => {
                        setComment(e.target.value)
                        if (translate) {
                          setTranslates({...translate, comment: e.target.value})
                        }
                      }}
                    /> */}
                  </div>{' '}
                </div>

             
              </form>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
                ref={closeM}
                onClick={() => setLoading(false)}
              >
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' className='btn btn-primary' onClick={updateCategory}>
                  Modifier
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
