import React, {ChangeEvent, useEffect, useState} from 'react'
import {createFormData, decodeHtmlTags, encodeHtmlTags, useCallApi} from '../../app/modules/utils'
import {useParams} from 'react-router-dom'
import {ContentState, EditorState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {DropDownCategorie} from '../DropDownCategorie'
import {DropZone} from '../DropZone'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../Loading'
import htmlToDraft from 'html-to-draftjs'
import {DropDownLanguage} from './DropDownLanguage'
import DOMPurify from 'dompurify'
import EditorQuell from './EditorQuell'

interface EditorContent {
  application: any
  caracteristique: any
  specification: any
}

const FormUpdateProduct = () => {
  const [lang, setLang] = useState({
    name: '',
    lang: 'en',
    img: '',
  })

  const callApi = useCallApi()
  let {slug} = useParams()
  const [filess, setFiles] = useState<File[]>([])
   const [editorContent, setEditorContent] = useState<any>({
    application: "",
    caracteristique: "",
    specification: "",
    produits:""
  })

  const [filepdf, setFilePdf] = useState<File>()
  const [loading, setLoading] = useState(false)
  const [selectSubCategorie, setSelectSubCategorie] = useState()
  const [translate, settranslate] = useState<any>()
  const [product, setProduct] = useState({
    titre: '',
    description: '',
    application: '',
    caracteristique: '',
    specification: '',
    avantages: '',
    imageurl: '',
    subcategorie: '',
    categorie: '',
    pdf: '',
    produits:'',
    parent: '',
    translates: [],
  })

  const getProductBySlug = async () => {
    const {data} = await callApi({
      route: `api/produits/${slug}`,
      method: 'GET',
    })

    setProduct({
      ...product,
      titre: data.titre,
      description: data.description,
      imageurl: data.imageurl,
      subcategorie: data.categorie,
      categorie: data.categorie.parent,
      avantages: data.avantages,
      pdf: data.pdfurl,
      parent: data.categorie.parent.id,
      specification: data.specification,
      caracteristique: data.caracteristique,
      produits:data.produits,
      application: data.application,
      translates: data.translates,
    })

    setSelectSubCategorie(data.categorie.id)
  }

 
  useEffect(() => {
    const {application, caracteristique, specification,produits} = product

    

    const translate: any = product.translates.find((translate: any) => translate.lang === lang.lang)

    setEditorContent({
      application: decodeHtmlTags(translate?.application || application),
      caracteristique: decodeHtmlTags(translate?.caracteristique || caracteristique),
      specification: decodeHtmlTags(translate?.specification || specification),
      produits: decodeHtmlTags(translate?.produits || produits)


     })
    
  }, [product, lang])

  useEffect(() => {
    // Set the initial EditorState values

    settranslate(product.translates.find((translate: any) => translate.lang === lang.lang))

    console.log('no')
  }, [lang])
  // Rest of your component code...

  const updateProduct = async () => {
    setLoading(true)
 console.log(filepdf);
 
    if (lang.lang === 'en') {
      try {
        const dataTosend = {
          image: filess[0],
          pdf: filepdf,
          categorie: selectSubCategorie,
          titre: product.titre,
          description: product.description,
          avantages: product.avantages,
          applications: encodeHtmlTags(editorContent.application)
          ,
          produits:encodeHtmlTags(editorContent.produits),
          caracteristiques: encodeHtmlTags(editorContent.caracteristique),
          specifications: encodeHtmlTags(editorContent.specification),
        }

        const formData = createFormData(dataTosend)
        const {data, status} = await callApi({
          route: `api/produits/${slug}`,
          method: 'POST',
          body: formData,
        })
        setLoading(false)
        toast.success('modifié')
      } catch (error: any) {
        setLoading(false)
        toast.error('Error updating : ' + error)
      }
    } else {
      try {
        const dataTosend = {
          pdf: filepdf,
          titre: translate.titre,
          description: translate.description,
          avantages: translate.avantages,
          applications: encodeHtmlTags(editorContent.application),
          caracteristiques: encodeHtmlTags(editorContent.caracteristique),
          specifications: encodeHtmlTags(editorContent.specification),
          produits:encodeHtmlTags(editorContent.produits)
        }

        console.log(dataTosend)
        const formData = createFormData(dataTosend)
        const {data, status} = await callApi({
          route: `api/translate_produits/${translate.id}`,
          method: 'POST',
          body: formData,
        })
        toast.success('modifié')

        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        toast.error('Error updating product : ' + error?.response.data.detail)
        console.log('Error updating translated product:', error)
      }
    }
  }

  const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
    setEditorContent({
      
      ...editorContent,
      [editorKey]: newEditorState,
    })
  }
  useEffect(() => {
    getProductBySlug()
  }, [slug])

  return (
    <div>
      <ToastContainer />
      <form className='mx-5'>
        <div className='form-group '>
          <DropDownLanguage
            addlang={false}
            langs={product.translates.map((translation: any) => translation.lang)}
            selectedLang={[lang, setLang]}
          />
        </div>
        {translate ? (
          <></>
        ) : (
          <>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              Photo :
            </label>
            <div className='form-group mb-5  '>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
                }}
              >
                <div className='d-flex'>
                  {filess.length == 0 ? (
                    <div
                      id='image'
                      className='image-input-wrapper w-125px h-125px mr-2 '
                      style={{
                        backgroundImage:
                          "url('https://api.nanogiga.fcpo.agency/" + product.imageurl + "')",
                      }}
                    ></div>
                  ) : (
                    filess.map((file) => {
                      return (
                        <div
                          id='image'
                          className='image-input-wrapper w-125px h-125px'
                          style={{
                            backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                          }}
                        ></div>
                      )
                    })
                  )}
                </div>

                <label
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Change avatar'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const {files} = e.target
                      if (files) {
                        setFiles(Array.from(files))
                      }
                    }}
                    type='file'
                    name='avatar'
                    accept='.png, .jpg, .jpeg'
                    multiple
                  />

                  <input type='hidden' name='avatar_remove' />
                </label>

                <span
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              </div>
            </div>
          </>
        )}

        <div className='form-group mb-5  '>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Titre de Produit :
          </label>
          <input
            onChange={(e: any) => {
              setProduct({...product, titre: e.target.value})
              if (translate) {
                settranslate({...translate, titre: e.target.value})
              }
            }}
            value={translate ? translate.titre : product.titre}
            type='text'
            className='form-control'
            name='titre'
            id='titre'
            placeholder='Titre de Machine'
          />
        </div>
        <div className='form-group mb-5  '>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Description de Produit :
          </label>
          <textarea
            onChange={(e: any) => {
              setProduct({...product, description: e.target.value})
              if (translate) {
                settranslate({...translate, description: e.target.value})
              }
            }}
            value={translate ? translate.description : product.description}
            className='form-control'
            name='description'
            id='titre'
            rows={4}
            placeholder='Titre de Machine'
          />
        </div>
        <div className='form-group mb-5  '>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Avanatges de Produit :
          </label>
          <textarea
            onChange={(e: any) => {
              setProduct({...product, avantages: e.target.value})
              if (translate) {
                settranslate({...translate, avantages: e.target.value})
              }
            }}
            value={translate ? translate.avantages : product.avantages}
            className='form-control'
            name='avantages'
            id='avantage'
            rows={4}
            placeholder='Avantage'
          />
        </div>
        <div className='form-group mb-5  row'>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Categorie :
          </label>
          <DropDownCategorie
            action='update'
            setSelectSubCategorie={setSelectSubCategorie}
            categorieId={product.categorie}
            subcategorieId={product.subcategorie}
          />
        </div>
        <div className='row'>
          <div className='form-group mb-5   '>
            <label className='mb-1 fw-bold' htmlFor='titre'>
              Application de Produit :
            </label>

            <EditorQuell value={{  onEditorStateChange}} title={"application"} data={editorContent.application}/>

          </div>
          <div className='form-group mb-5  '>
            <label className='mb-1 fw-bold' htmlFor='titre'>
              Specification de Produit :
            </label>

                            <EditorQuell value={{  onEditorStateChange}} title={"specification"} data={editorContent.specification}/>

          </div>
        </div>
        <div className='form-group mb-5  '>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Caractéristique de Produit :
          </label>

          <EditorQuell value={{  onEditorStateChange}} title={"caracteristique"}  data={editorContent.caracteristique}/>

        </div>
        <div className='mb-3 ' style={{}}>
                <label htmlFor='exampleFormControlTextarea1' className='form-label  fw-bold fs-6'>
                  Liste des Produits :
                </label>
                <EditorQuell value={{  onEditorStateChange}} title={"produits"} data={editorContent.produits}/>

              </div>
        
        <div className='form-group mb-5  '>
          <label className='mb-1 fw-bold' htmlFor='titre'>
            Pdf :
          </label>
          <DropZone setFilePdf={setFilePdf} pdf={translate ? translate.pdfurl : product.pdf} />
        </div>
        <div className='d-flex justify-content-end '>
          {loading ? (
            <button type='button' className='btn btn-primary text-end'>
              <Loading />
            </button>
          ) : (
            <button type='button' onClick={updateProduct} className='btn btn-primary text-end'>
              Modifier
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default FormUpdateProduct
