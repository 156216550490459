import React from 'react'
import { Header } from '../../../_metronic/layout/components/header/Header'
import { FormAddProduct } from '../../../components/componentsProduit/FormAddProduct'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

export const AddProduct = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Sous sous-catégorie",
      path: "/products/all",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Ajouter Sous sous-catégorie",
      path: "/products/add",
      isSeparator: true,
      isActive: true,
    },
  ];
  return (
    <div>
    
      <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter Sous sous-catégorie</PageTitle>
      <FormAddProduct/>
    </div>
  )
}
