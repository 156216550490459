import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { useParams } from 'react-router-dom';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  createFormData, decodeHtmlTags, encodeHtmlTags, useCallApi } from '../../app/modules/utils';
import { Loading } from '../Loading';
import htmlToDraft from 'html-to-draftjs';
import { toast } from 'react-toastify';
import { DropDownLanguage } from './DropDownLanguage';
import { ModalAddLangSubProduct } from './ModalAddLangSubProduct';
import EditorQuell from './EditorQuell';

type Props={
    detail:any,
    id:number
    deleteSubProduct:(id:number)=>void
  }
  interface EditorContent {
    specification: any;
  
  }
export const ItemSubProduct:React.FC<Props>  = ({detail,id,deleteSubProduct}) => {


  
    const {slug}=useParams()
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [filess, setFiles] = useState<File[]>([])
    const [translate, setTranslate] = useState<any>()
    console.log(translate);
    const callApi = useCallApi();
    const [lang, setLang] = useState({
      name: '',
      lang: 'en',
      img: '',
    })
    const [editorContent, setEditorContent] = useState<EditorContent>({
     
      specification: "",
    });

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

   
    const updateSubProduct = async() => {

      try {
                  setLoading(true)        

        if(translate){
          const dataTosend = {
            details:"api/details/"+ id,
           
            specifications: encodeHtmlTags(editorContent.specification),
          };
          console.log(dataTosend);
          
       return 
          const {data,status}  = await callApi({
          route: `api/translate_details/`+translate.id,
          method: "PUT",
          body:dataTosend
        
        });
        
   
        }else{
          // setLoading(true)        
          const dataTosend = {
            image: filess[0],
            produit: slug,
            specifications: encodeHtmlTags(editorContent.specification),
          };
          
     
          const formData = createFormData(dataTosend);  
          const {data,status}  = await callApi({
          route: `api/details/`+id,
          method: "POST",
          body:formData
        
        });
        console.log(status);
        
       
        }
        setLoading(false)
        toast.success("mdofié")
      } catch (error:any) {
        setLoading(false)
        toast.error("Error updating "+error?.response.data.detail)
      }
     
       
       
      };


    const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
      setEditorContent({
        ...editorContent,
        [editorKey]: newEditorState,
      });
    };

useEffect(() => {
 
  const convertToContentState = (html: string) => {
    const { contentBlocks, entityMap } = htmlToDraft(html);
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  };

  const translate:any= detail.translates.find((translate: any) => translate.lang === lang.lang);
  
  setEditorContent({
   
    specification:   decodeHtmlTags(translate?.specification || detail.specification ),
  });

}, [lang])

   useEffect(() => {
    setTranslate(detail.translates.find((translate: any) => translate.lang === lang.lang))
   }, [lang])
    
    
  return (
    <div className='mb-5'>
       <Accordion
        expanded={expanded === "panel" + detail.id}
        onChange={handleChange("panel" + detail.id)}
      >
        <AccordionSummary
          expandIcon={
            <>
              {" "}
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
            </>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
         <h4>{"Details "+ (id+1)}</h4> 
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="d-flex" >
      
          <Typography>
            <div className="col">
            {
              translate?<></>: <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{
              backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
              }}
          >
              <div className='d-flex'>
              {filess.length == 0 ? (
                  <div
                  id='image'
                  className='image-input-wrapper w-125px h-125px mr-2 '
                  style={{
                      backgroundImage:
                      "url('https://api.nanogiga.fcpo.agency/" +detail.imageurl+ "')",
                  }}
                  ></div>
              ) : (
                  filess.map((file) => {
                  return (
                      <div
                      id='image'
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                          backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                      }}
                      ></div>
                  )
                  })
              )}
              </div>

              <label
              className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              title='Change avatar'
              >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const {files} = e.target
                  if (files) {
                      setFiles(Array.from(files))
                  }
                  }}
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  multiple
              />

              <input type='hidden' name='avatar_remove' />
              </label>

              <span
              className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              title='Cancel avatar'
              >
              <i className='bi bi-x fs-2'></i>
              </span>
          </div>
             
                
            
            }
            </div>
           
            
           
     
          </Typography>
          <Typography>
            <div className="col d-block" style={{marginLeft:"33px"}}>
            <DropDownLanguage addlang={false} langs={detail.translates.map((translation: any) => translation.lang)} selectedLang={[lang,setLang]}/>
            <EditorQuell data={editorContent.specification} value={{  onEditorStateChange}} title={"specification"}/>

           </div>
           <div className="d-flex">
           <button  
            type={"button"}
            data-bs-toggle='modal'
            data-bs-target={'#modaladdlangsubproduct'+id}
            className="btn  btn-bg-light btn-active-color-danger  me-1"
            style={{color:"#7d8090",marginLeft:"33px",marginTop:"33px"}}
           >
           <KTSVG
              path="/media/icons/duotune/general/language.svg"
              className="svg-icon-2"
            />
             Ajouter une version de langue
            </button>
            <ModalAddLangSubProduct id={id}/>
            {
              loading?
               <button
              type={"button"}
              title="save"
              className="btn  btn-bg-light btn-active-color-primary  me-1"
              style={{color:"#7d8090",marginLeft:"33px",marginTop:"33px"}}
              // onClick={() => updateCategorie(slug)}
            >
              
            
              <Loading/>
            </button>:  
             <button
            type={"button"}
            title="save"
            className="btn  btn-bg-light btn-active-color-primary  me-1"
            style={{color:"#7d8090",marginLeft:"33px",marginTop:"33px"}}
            onClick={() => updateSubProduct()}
          >
            
            <KTSVG
              // امين
              path="/media/icons/duotune/general/gen005.svg"
              className="svg-icon-3" 
              
            
            />
            
            Modifier
          </button>
            }
            <button  
            type={"button"}
            title="save"
            className="btn  btn-bg-light btn-active-color-danger  me-1"
            style={{color:"#7d8090",marginLeft:"33px",marginTop:"33px"}}
            onClick={() => deleteSubProduct(id)}>
          <KTSVG
              // امين
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3" 
          
            />
            </button>
           </div>
            
           
          
          </Typography>
    
       
        </AccordionDetails>
      </Accordion>

          
    </div>
  )
}
