import React from 'react'
import { FormAddLangProduct } from '../../../components/componentsProduit/FormAddLangProduct'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

export const AddLangProduct = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Sous-sous catégories",
      path: "/products/all",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Ajouter une version de langue",
      path: "/pages/managementGlobal/listcategories",
      isSeparator: true,
      isActive: true,
    },
  ];
  return (
    <>
       <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter une version de langue </PageTitle>
       <FormAddLangProduct/> 
    </>
  )
}
