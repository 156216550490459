
import React, { ChangeEvent, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import './css/mystyle.css'
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  createFormData, encodeHtmlTags, useCallApi } from '../../app/modules/utils';
import { useParams } from 'react-router-dom';
import EditorQuell from './EditorQuell';


interface EditorContent {
    specification: any;
 
}
export const FormAddSubProduct = () => {


    const {slug}=useParams();
    const callApi = useCallApi();
    const [image, setImage] = useState<File | any>(null)
    const [file, setFile] = useState<string>('')
    const [editorContent, setEditorContent] = useState<EditorContent>({
      specification:"",

    });
 
  
    const onEditorStateChange = (editorKey: keyof EditorContent, newEditorState: EditorState) => {
      setEditorContent({
        ...editorContent,
        [editorKey]: newEditorState,
      });
    };
  
  
    
    const handleSubmit = async(e:any) => {
      e.preventDefault()

      try {
        const dataTosend = {
          produit:slug,
          image: image,
          specification:encodeHtmlTags(editorContent.specification),
        };
        
       
        const formData = createFormData(dataTosend);  
        const data  = await callApi({
        route: `api/details`,
        method: "POST",
        body:formData
      });
      toast.success("ajouté ")
      
      } catch (error:any) {
           toast.error("Error adding  : "+error?.response.data.detail)
           console.log(error?.response.data.detail);
           
      }
      
  
  
    
  
  }

 
  
  return (
    <>
    <ToastContainer />
      <form className='mx-5' onSubmit={handleSubmit}>
        
        <label className=' fw-bold fs-6' htmlFor='exampleInputEmail1'>
          Photo :
        </label>
        <div className='form-group mb-5  '>
          <div
            className='image-input image-input-outline'
            data-kt-image-input='true'
            style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
          >
            <div
              id='image'
              className='image-input-wrapper w-125px h-125px'
              style={{backgroundImage: file === '' ? "url('')" : 'url(' + file + ')'}}
            ></div>
            <label
              className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  // const reader = new FileReader();
                  // reader.onload=function(e){
                  //   setUrl(e.target?.result)
                  // }
                  // reader.readAsDataURL(e.target.files[0])
                  if (e.target.files && e.target.files[0]) {
                    setFile(URL.createObjectURL(e.target.files[0]))
                    setImage(e.target.files[0])
                  }
                }}
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
              />
              <input type='hidden' name='avatar_remove' />
            </label>

            <span
              className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span>
          </div>
        </div>
       

        <div className='mb-3'>
          <label
            htmlFor='exampleFormControlTextarea1'
            className='form-label  fw-bold fs-6'
          >
            Specification :
          </label>
          <EditorQuell value={{  onEditorStateChange}} title={"specification"}/>

        </div>

      

        <div className='d-flex justify-content-end mb-4'>
          <button type='submit' className='btn btn-primary text-end'>
            Ajouter
          </button>
        </div>
      </form>

     
   </>
  )
}
