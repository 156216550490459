import React, { useEffect, useState } from 'react'
import { showToast, useCallApi } from '../../app/modules/utils'
import { ToastContainer, toast } from 'react-toastify'
import { KTSVG, useDebounce } from '../../_metronic/helpers'
import { Loading } from '../Loading'
import ModalAddCategory from '../componentCategory/ModalAddCategory'
import SearchComponent from '../SearchComponent'
import { StatisticsWidget2 } from '../../_metronic/partials/widgets'
import { ItemPosts } from './ItemPosts'
import ModalAddPost from './ModalAddPost'

export const ListPosts = () => {
    const [posts, setPosts] = useState([])
    const [file, setFile] = useState<string>('')
    const [loading, setLoading] = useState(true)
    const callApi = useCallApi()
    const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
    const getposts = async () => {
      const {data} = await callApi({
        route: 'api/posts',
        method: 'GET',
      })
      setPosts(data)
      setLoading(false)
    }

    useEffect(() => {
      getposts()
    }, [])
  
    const deletePost = async (slug: string) => {
      if (window.confirm('Voulez-vous vraiment supprimer ce post !')) {
        const filteredposts = posts.filter((post: any) => post.slug !== slug)
          setPosts(filteredposts) 
          toast.success("Post  supprimé")
          const {data} = await callApi({
          route: 'api/posts/' + slug,
          method: 'DELETE',
        })
     
      
      }
    }
    const getPostByName= async ()=>{
      setLoading(true)
      const {data}= await callApi({
          route: 'api/posts?title='+debouncedSearchTerm,
          method: "GET",
        });
        setPosts(data)
        setLoading(false)
  }

    
    useEffect(() => {
        getPostByName()
    }, [debouncedSearchTerm])

  return (
    <div>
    <ToastContainer />
    <div className={`card  mb-5 `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
       {/*  <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <SearchInput searching={searching} placeholder="Catégoeie"/>
          </span>
        </h3> */}
        <SearchComponent action="add"placeholder={"Recherche Devis"} searchQuery={[searchTerm,setSearchTerm]} />

        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            type='button'
            className='btn btn-sm btn-primary '
            data-bs-toggle='modal'
            data-bs-target='#modalpost'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
            Nouvelle Poste
          </button>

          <ModalAddPost />
        </div>
      </div>
    </div>
      {loading ? (
        <Loading />
      ) : (
      
        <div className='row g-3 g-xl-3'>
          {
            posts.map((post:any, index) => {
              return (
                 <div className='col-xl-4'>
                  <ItemPosts  key={index}deletePost={deletePost} slug={post.slug} className='card-xl-stretch mb-xl-8' description={post.description} title={post.title} avatar={post.imageurl} translates={post.translates}/>
                </div> 
                // <ItemPartenaire
                //   deletePartenaire={deletePartenaire}
                //   key={index}
                //   data={partner}
                //   className=''
                // />
              )
            })
          }
          </div>
             
      )}
      {/* end::Header */}
      {/* begin::Body */}

      {/* begin::Body */}
  
  </div>
  )
}

