import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { createFormData, useCallApi } from '../../app/modules/utils';
import { Loading } from '../Loading';

const ModalPartenaire = () => {
    const callApi = useCallApi();
    const [image, setImage] = useState<File | any>(null);
    const [file,setFile]=useState<string>("");
    const [loading, setLoading] = useState(false)
    const initialValues = {
      partenaire: "",
      image: "",
      lien: "",
  
    };
      const handelSumit=async(values:any)=>{
      
          
        try {
          setLoading(true)
          const dataTosend = {
            image: image,
            title: values.partenaire,
            link: values.lien,
           
          };
          
          const formData = createFormData(dataTosend);  
          const data  = await callApi({
          route: `api/partenaires`,
          method: "POST",
          body:formData
           });  
           toast.success("ajouté")
           setLoading(false)

        } catch (error:any) {
          toast.error("Error adding : "+error?.response.data.detail)
          setLoading(false)

        }
        
  
      
      }
    return (
      <>
 
  <div   className="modal fade"
        id="modalpartner"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
    <div className="modal-dialog" role="document">
  
      <Formik
        initialValues={initialValues}
        onSubmit={(values)=>handelSumit(values)}>
          {()=>( 
          <Form className="modal-content" >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Ajouter Partenaire</h5>
            <button type="button" className="btn close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
              <form>
              <label className='fw-bold fs-6'  htmlFor="exampleInputEmail1">Photo :</label>    
              <div className="form-group mb-5  ">
                <div className="image-input image-input-outline" data-kt-image-input="true" style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}>
                <div id="image" className="image-input-wrapper w-125px h-125px" style={{backgroundImage: file==""? "url('')" :"url("+file+")"}} >
  
              </div>
                <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Change avatar">
                  <i className="bi bi-pencil-fill fs-7"></i>
  
                  <input  onChange={(e:ChangeEvent<HTMLInputElement>)=>{
  
                      // const reader = new FileReader();
                      // reader.onload=function(e){
                      //   setUrl(e.target?.result)
                      // }
                      // reader.readAsDataURL(e.target.files[0])
                      if (e.target.files && e.target.files[0]) {
                        setFile(URL.createObjectURL(e.target.files[0]));
                        setImage(e.target.files[0]);
                        
                      }
                    }}
                    type="file" name="image"  accept=".png, .jpg, .jpeg" />
                    <input type="hidden" name="avatar_remove" />
                </label>
  
                <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="cancel"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Cancel avatar">
                    <i className="bi bi-x fs-2"></i>
                </span>
  
            </div>
              </div>
                <div className="form-group mb-5">
                  <label    className='fw-bold fs-6'htmlFor="exampleInputEmail1">Partenaire :</label>
                  <Field type="text" className="form-control" id="exampleInputEmail1" name="partenaire"aria-describedby="text" placeholder="partenaire" required/>
                </div>
                <div className="form-group">
                  <label  className='fw-bold fs-6' htmlFor="exampleInputEmail1">Lien :</label>
                  <Field type="text" className="form-control" id="exampleInputEmail1" name="lien"aria-describedby="text" placeholder="lien" required/>
                </div>
            
  
        
        </form>
  
  
  
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            {
              loading?            <button className="btn btn-primary"><Loading/></button>
:            <button type="submit" className="btn btn-primary">Ajouter</button>

            }
          </div>
                
          </Form>)}
      </Formik>
    </div>
  </div>
  </>
     
  )
}

export default ModalPartenaire