import React, { useState } from 'react'
import { useLocation, useParams, useRoutes } from 'react-router-dom'
import { createFormData, useCallApi } from '../../modules/utils';
import FormUpdateProduct from '../../../components/componentsProduit/FormUpdateProduct';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';

export const UpdateProduct = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Produits",
      path: "products/all",
      isSeparator: false,
      isActive: false,
    }
  ];

    return (
    <>
          <PageTitle breadcrumbs={usersBreadcrumbs}>Modifier Produit </PageTitle>

        <FormUpdateProduct/>
    </>
  )
}
