import React, { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor from 'jodit-react';
import 'quill-better-table';

type Props = {
  value: any;
  title: string;
  data?: any;
};

const EditorQuell: React.FC<Props> = ({ value, title, data }) => {
  const editor = useRef<any>(null); // Use a more specific type for the ref
  const [content, setContent] = useState<any>(data);

  useEffect(() => {
    console.log(data);
    
    setContent(data);
  }, [data]);

  const handleEditorStateChange = (val: any) => {
    setContent(val);
    value.onEditorStateChange(title, val);
  };

 


const uploaderConfig = {
  imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
  filesVariableName: function (t: number): string {
      return 'files[' + t + ']';
  },
  url: `${process.env.REACT_APP_API_URL}/api/image_products`,
  withCredentials: false,
  format: 'json',
  method: 'POST',
  prepareData: function (formdata: FormData): FormData {
      return formdata;
  },
  isSuccess: function (this: any, e: any): boolean {
      const fn = this.jodit

      if (e.data.files && e.data.files.length) {
          const tagName = 'img';
          e.data.files.forEach((filename: string, index: number) => {
              const elm = fn.createInside.element(tagName);
              elm.setAttribute('src', `${process.env.REACT_APP_API_URL}/images/products/${filename}`);
              fn.s.insertImage(elm as HTMLImageElement, null, fn.o.imageDefaultWidth);
          });
      }

      return e.success;
  },
  getMessage: function (e: any): string {
      return e.data.messages && Array.isArray(e.data.messages)
          ? e.data.messages.join('')
          : '';
  },
  process: function (resp: any): { files: any[]; error: string; msg: string } {
      let files: any[] = [];
      files.unshift(resp.data);
      return {
          files: resp.data,
          error: resp.msg,
          msg: resp.msg,
      };
  },

  error: function (this: any, e: Error): void {
      this.j.e.fire('errorMessage', e.message, 'error', 4000);
  },

  defaultHandlerError: function (this: any, e: any): void {
      this.j.e.fire('errorMessage', e.message);
  },
};

const handleSearch = () => {
  // Implementez votre logique de recherche ici
  // Vous pouvez accéder au contenu de l'éditeur avec editor.current.value
  console.log("Effectuer une recherche :", editor.current.value);
};
const config = useMemo(
  () => ({
      readonly: false,
      placeholder: 'Start typing...',
      toolbarAdaptive: false,
      useSearch: false,
      language: "en",
      allowResizeX: false,
      allowResizeY: false,
      height: 400,
      enableDragAndDropFileToEditor: true,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,

      buttons: [
          'source',
          '|',
          'bold',
          'italic',
          'underline',
          '|',
          'ul',
          'ol',
          '|',
          'image',
          'file',
          '|',
          'video',
          '|',
          'link',
          '|',
          'undo',
          'redo',
          '|',
          'hr',
          '|',
          'eraser',
          '|',
          'font',
          'fontsize',
          'paragraph',
          'brush',
          '|',
          'table',
       
          'fullsize',
      ],
      extraButtons: [
        {
          name: 'searchButton',
          icon: 'search',
          exec: handleSearch,
        },
      ],
      uploader: uploaderConfig,
  }),
  []
);
  
  

 

  return (
    <div>
      <JoditEditor
            ref={editor}
            value={content}
            config={config}
             onChange={(newvalue:any)=>handleEditorStateChange(newvalue)}

        />
    </div>
  );
};

export default EditorQuell;
