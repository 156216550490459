import React from 'react'
import { ListProducts } from '../../../components/componentsProduit/ListProducts'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { SearchComponent } from '../../../_metronic/assets/ts/components';

const ListProduct = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Sous-sous catégorie",
      path: "products/all",
      isSeparator: false,
      isActive: false,
    },
  ];
  return (
    <>
      
        <PageTitle breadcrumbs={usersBreadcrumbs}>liste des Sous-sous catégories</PageTitle>
        <ListProducts/>
    </>
        
    
  )
}

export default ListProduct