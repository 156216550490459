/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl, useDebounce } from '../../_metronic/helpers'
import SearchComponent from '../SearchComponent'
import ItemEstimates from './ItemEstimates'
import { useCallApi } from '../../app/modules/utils'

type Props = {
  className: string
}

const EstimatesList: React.FC<Props> = ({className}) => {

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [estimates, setEstimates] = useState([])
  const [loading, setLoading] = useState(true)

  const callApi = useCallApi()
  const getDevsi=async()=>{
    const {data} = await callApi({
      route: 'api/devis.json',
      method: 'GET',
    })
    setEstimates(data)
    setLoading(false)
  }

  useEffect(() => {
     getDevsi()
}, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
         <SearchComponent action="add"placeholder={"Recherche par date"} searchQuery={[searchTerm,setSearchTerm]}/>
        <div className='card-toolbar'>
          {/* <a href='#' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Member
          </a> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>   
              <th className='ps-4 min-w-125px rounded-start'>Date</th>
              <th className='ps-4 min-w-200px rounded-start'>Client</th>
                <th className='ps-4 min-w-325px '>Product</th>
                <th className='min-w-125px'>Quantité</th>
             
                <th className='min-w-150px'>Status</th>
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                estimates.map((estimate,index)=>{
                  return <ItemEstimates estimate={estimate} key={index}/>
                })
              }
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {EstimatesList}
