/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'

import { DropDownCategory } from './DropDownCategory'
import { ModalUpdateCategory } from './ModalUpdateCategory'
import ModalAddLang from './ModalAddLang'
// import ModalUpdateCategory from './ModalUpdateCategory'


type Props = {
  categorie:any,
  deletCategorie:(slug:string)=>void,
  getCategories:()=>void
}

const ItemCategorie: React.FC<Props> = ({categorie,deletCategorie,getCategories}) => {
  console.log(categorie);

  return (
    <div className={`card `}>
      {/* begin::Body */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bold text-dark'>Tasks Overview</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Pending 10 tasks</span> */}
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold"
            role="button" id="dropdownMenuLnk"
            data-bs-toggle="dropdown"
             aria-expanded="false"

          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <DropDownCategory  deleteCategory={deletCategorie} slug={categorie.slug}/>
          <ModalUpdateCategory getCategories={getCategories}categorie={categorie}/>
          <ModalAddLang categorie={categorie}/>

          {/* end::Menu */}
        </div>
      </div>
      <div className='card-body  align-items-center pt-3 pb-0'>
        

        <img src={"https://api.nanogiga.fcpo.agency/"+categorie.imageurl} alt='' className='align-self-end h-60px rounded' />
        <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
          <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
            {categorie.categorie}
          </a>
           
          {/* <span
            className='fw-semibold text-muted fs-5'
            dangerouslySetInnerHTML={{__html: categorie.description}}
          ></span> */}
        </div>
        {
           categorie.parent &&
           <div className='d-flex align-items-center mb-5 '>
            <span className='fw-semibold text-muted '>parent</span>   &rarr;  &nbsp; <a
             href='#'
             className='btn btn-sm btn-light-danger btn-color-muted btn-active-light-success px-4 py-2 ml-1 '
           >
             
           {categorie.parent.categorie} 
           </a>
         
       
         </div>
        }
        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ItemCategorie}
