import React from 'react';
import {useDropzone} from 'react-dropzone';
import { KTSVG } from '../_metronic/helpers';

type Props={
    pdf:string,
    setFilePdf:(file:any)=>void
}
export const DropZone:React.FC<Props> = ({pdf,setFilePdf})=> {

    
  const {getRootProps, getInputProps, open,acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFilePdf(acceptedFiles[0]);
      }
    },
  });
 
  const files = acceptedFiles.map((file:any )=> (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const removeFile = () => {
    setFilePdf([]); // Update the main selected file if needed
  };
  
  // setFilePdf(files[0])
  return (
    <div className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        
        <p>{files.length!=0?files:<li>{pdf?.split('/').pop()}</li>}</p>
        <button className='btn me-2' type={"button"} style={{ background:"#7ec7f0",color:"white"}}onClick={()=>{
          open()
        
          }}>
          Télécharger
        </button>
     
       
      </div>
      <aside>
      
        
       
      </aside>
    </div>
  );
}